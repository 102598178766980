<template>
    <el-dialog custom-class="custom-dialog" :visible.sync="visible" class="forecast-results-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :append-to-body="true" top="50px" width="96%">
        <div slot="title" class="flex-row align-items-center justify-between border-bottom padding-bottom-10 padding-right-40">
          <div>模型构成对比（{{modelType.find(v=>{return v.value==expertType}).name}} - {{modelFreq=='week'?'周度预测':'月度预测'}}）</div>
          <div>
            <el-select v-model="modelCycle" size="mini" @change="getData" class="margin-right-20" v-if="modelFreq=='month'">
              <el-option :value="1" label="移动1期预测方案"></el-option>
              <el-option :value="3" label="移动3期预测方案"></el-option>
              <el-option :value="12" label="移动12期预测方案"></el-option>
            </el-select>
            <el-button plain size="mini" @click="downLoadDataBySelect">导出模型构成对比</el-button>
          </div>
          
        </div>
        <div :style="{'height': $root.docHeight-200+'px'}" v-loading="loading" v-if="visible">

            <el-table
              :key="`${targetId}-${expertType}-${modelFreq}-${modelCycle}`"
              :max-height="$root.docHeight-200"
              :data="tableData.items"
              size="small"
              style="width: 100%">
              <div slot="empty"><el-empty :image-size="80"></el-empty></div>
              <el-table-column label="因子" show-overflow-tooltip min-width="150" fixed="left">
                <template slot-scope="props">{{ props.row.divisorName }}</template>
              </el-table-column>
              <el-table-column label="单位" align="center" show-overflow-tooltip min-width="50" fixed="left">
                <template slot-scope="props">{{ props.row.unit }}</template>
              </el-table-column>
              <el-table-column align="center" v-for="(item,index) in tableData.dataList" :key="index" :label="$dateFormat(item.dataCycle,modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')">
                <el-table-column align="center" label="真实值">
                  <template slot-scope="props">{{ item[props.row.id]['hisValue'] | numFormat(2,'-') }}</template>
                </el-table-column>
                <el-table-column align="center" v-for="(item2,index2) in plans" :key="index2" :label="`${$dateFormat(item2.predictCycle,modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}方案预测`">
                  <template slot-scope="props">{{ item[props.row.id][item2.publishId] | numFormat(2,'-') }}</template>
                </el-table-column>
                
              </el-table-column>
              
            </el-table>

        </div>
      </el-dialog>
</template>
<script>
import exportExcel  from "@/common/exportExcel";
export default ({
   name:'ModelCompositionTable',
   components:{

   },
   props:{

    modelFreq:{
      type:String,
      default:'week'
    },
    targetId:{
      type:Number,
      default:0
    },
    expertType:{
      type:String,
      default:'ai'
    },
   },
   data(){
    return{
      visible:false,
      loading:false,
      tableData:{},
      modelCycle:1,
      plans:[],
      modelType:[
        {name:'卓创赋值预测',value:'sci'},
        {name:'滨化赋值预测',value:'befar'},
        {name:'模型自估预测',value:'ai'}
      ]
    }
   },
   computed:{
  
   },
   created(){

   },
   methods:{
    async getData(){
      await this.getPublish()
      this.getComparison()
    },
    //获取对比结果
    getPublish(){
      this.loading=true
      let request=this.$instance.get('/resultView/getPublishIds',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           targetId:this.targetId,
           expertType:this.expertType
          },
      })
      request.then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.plans=res.data.info
        }else{
          this.plans=[]
        }
      })
      request.catch(()=>{
        this.loading=false
        this.plans=[]
      })
      return request
    },
    getComparison(){
      this.loading=true
      let request=this.$instance.get('/resultView/getDivisorsOfModelByTarget',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           modelCycle:this.modelFreq=='week'?1:this.modelCycle,
           targetId:this.targetId,
           expertType:this.expertType
          },
      })
      request.then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.tableData=res.data.info
        }else{
          this.tableData={}
        }
      })
      request.catch(()=>{
        this.loading=false
        this.tableData={}
      })
      return request
    },
    //导出所选
    downLoadDataBySelect(){
      
      let query={
        modelFreq:this.modelFreq,
        modelCycle:this.modelFreq=='week'?1:this.modelCycle,
        targetId:this.targetId,
        expertType:this.expertType
      }
      exportExcel('/resultView/exportModelOfDivisorResultByTarget',query,'get');
    },
   }
})
</script>

<style lang="scss">

  .forecast-results-dialog{
    .el-tabs__header{margin: 0;}
    .el-dialog__body{
      padding-top:0px
    }
  }

</style>